<template lang="pug">
#update-browser-permissions.app-modal-wrapper
  .app-modal
    .app-modal-bg
    keep-alive
      .modal-content
        .modal-header
          h2 Please update your browser settings

        .modal-text(v-if="isSafari")
          ol
            li Open the Settings app on your device
            li Select 'Safari' from the list of settings
            li Under 'Settings for Websites' ensure that both Microphone and Camera are set to either 'Ask' or 'Allow'

        .modal-text(v-else)
          ol
            li Open the Chrome app on your device
            li In the Chrome menu, select 'Settings'
            li Select 'Site Settings'
            li Ensure that both Microphone and Camera are set to 'Ask first'

        .modal-footer
          button.button.action-btn(@click="performAction") I've updated my {{ browserName }} permissions
</template>

<script>
export default {
  props: {
    retry: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      isSafari: false,
    }
  },

  created() {
    this.isSafari = /iphone/i.test(navigator.platform)
  },

  computed: {
    browserName,
  },

  watch: {},

  methods: {
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function browserName() {
  return this.isSafari ? 'Safari' : 'Chrome'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.retry()
  this.$modal.close('UpdateBrowserPermissions')
}
</script>
